exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artifacts-js": () => import("./../../../src/pages/artifacts.js" /* webpackChunkName: "component---src-pages-artifacts-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-lets-plan-js": () => import("./../../../src/pages/lets-plan.js" /* webpackChunkName: "component---src-pages-lets-plan-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-te-tirumala-govinda-namalu-js": () => import("./../../../src/pages/te/tirumala/govinda-namalu.js" /* webpackChunkName: "component---src-pages-te-tirumala-govinda-namalu-js" */),
  "component---src-pages-tirumala-brahmotsavam-js": () => import("./../../../src/pages/tirumala/brahmotsavam.js" /* webpackChunkName: "component---src-pages-tirumala-brahmotsavam-js" */),
  "component---src-pages-tirumala-famous-places-to-visit-js": () => import("./../../../src/pages/tirumala/famous-places-to-visit.js" /* webpackChunkName: "component---src-pages-tirumala-famous-places-to-visit-js" */),
  "component---src-pages-tirumala-faqs-darshan-js": () => import("./../../../src/pages/tirumala/faqs/darshan.js" /* webpackChunkName: "component---src-pages-tirumala-faqs-darshan-js" */),
  "component---src-pages-tirumala-festivals-js": () => import("./../../../src/pages/tirumala/festivals.js" /* webpackChunkName: "component---src-pages-tirumala-festivals-js" */),
  "component---src-pages-tirumala-govinda-namalu-js": () => import("./../../../src/pages/tirumala/govinda-namalu.js" /* webpackChunkName: "component---src-pages-tirumala-govinda-namalu-js" */),
  "component---src-pages-tirumala-history-js": () => import("./../../../src/pages/tirumala/history.js" /* webpackChunkName: "component---src-pages-tirumala-history-js" */),
  "component---src-pages-tirumala-index-js": () => import("./../../../src/pages/tirumala/index.js" /* webpackChunkName: "component---src-pages-tirumala-index-js" */),
  "component---src-pages-tirumala-ways-to-book-tickets-js": () => import("./../../../src/pages/tirumala/ways-to-book-tickets.js" /* webpackChunkName: "component---src-pages-tirumala-ways-to-book-tickets-js" */),
  "component---src-pages-tirupati-famous-places-to-visit-js": () => import("./../../../src/pages/tirupati/famous-places-to-visit.js" /* webpackChunkName: "component---src-pages-tirupati-famous-places-to-visit-js" */),
  "component---src-pages-tirupati-index-js": () => import("./../../../src/pages/tirupati/index.js" /* webpackChunkName: "component---src-pages-tirupati-index-js" */),
  "component---src-pages-tirupati-places-to-visit-around-js": () => import("./../../../src/pages/tirupati/places-to-visit-around.js" /* webpackChunkName: "component---src-pages-tirupati-places-to-visit-around-js" */),
  "component---src-templates-how-to-js": () => import("./../../../src/templates/how-to.js" /* webpackChunkName: "component---src-templates-how-to-js" */)
}

